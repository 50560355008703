import { Link } from "react-router-dom";

export default function Card({ text, image, to })
{
    return (
        <Link to={to}>
            <div className="card">
                <div className="card-image">
                    <img src={image} alt="" />
                </div>
                <div className="card-text">
                    {text}
                    <img src="/images/arrow-right.svg" alt="" />
                </div>
            </div>
        </Link>
    )
}