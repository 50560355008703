export default function Step3({ click, prevClick, setDisplay })
{
    return (
        <div className="step3" style={{ display: setDisplay }}>
            <div className="gen">
                <div className="gender">
                    <input type="radio" name="gender" className="form-check-input" id="male" defaultChecked value="male" />
                    <label htmlFor="male">Male</label>
                </div>
                <div className="gender">
                    <input type="radio" className="form-check-input" id="female" name="gender" value="female" />
                    <label htmlFor="female">Female</label>
                </div>
            </div>
            <div style={{ display: "flex", gap: "1rem" }}>
                <button type="button" id="back" className="btn btn-primary" onClick={prevClick}>Previous</button>
                <button type="submit" className="btn btn-primary">Submit</button>
            </div>
        </div>
    )
}