import { Link } from "react-router-dom";

export default function Btn({ text, rounded })
{
    return (
        <Link to={'/register'}>
            <button type="button" className={rounded + ' cust-btn'}>
                {text}
            </button>
        </Link>
    )
}