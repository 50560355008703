import Btn from "../components/Button";
import Req from "../components/Requirement";

export default function ProductDesign()
{
    return (
        <>
            <h2 id="courseName">Product Design</h2>
            <div className="course-details">
                <img src="/images/pd.png" alt="" className="details-image" />
                <p className="text">
                    Product Design: Shaping Tomorrow's Experiences
                    <br />
                    Product design is the art of crafting user experiences. It's about understanding needs, creating intuitive interfaces, and ensuring delightful interactions. Product designers use their creativity to shape the way we interact with technology and the world around us.

                    From mobile apps to physical products, designers are the architects of user-centered solutions. They collaborate closely with teams, conduct research, and iterate on designs to create products that resonate with people.

                    Whether you're a seasoned designer or just starting your journey, product design offers a dynamic and rewarding career. Join us in shaping tomorrow's experiences!
                    <br />
                    <Btn text={'Register'} rounded={'rounded'} />
                </p>
            </div>
            <Req courseName={'Product Design'} image={'/images/pd2.png'} sysReq={'4gb RAM, 256GB Disk Space'} time={'2 hours'} price={'200,000'} dollarPrice={Math.round(200000 / 1200)} />
        </>
    )
}