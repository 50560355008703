import { BrowserRouter, Link, Route, Routes } from 'react-router-dom';

import './style.scss';
import Header from './components/Header';
import Cards from './pages/Cards';
import SEN from './pages/Engineering';
import CyberSecurity from './pages/Security';
import ProductDesign from './pages/Design';
import DataScience from './pages/Data';
import Register from './pages/Register';
import Footer from './components/Footer';
function App()
{
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Routes>
          <Route index element={<Cards />} />
          <Route path='/software' element={<SEN />} />
          <Route path='/hacking' element={<CyberSecurity />} />
          <Route path='/productdesign' element={<ProductDesign />} />
          <Route path='/datascience' element={<DataScience />} />
          <Route path='/register' element={<Register />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}
export default App;