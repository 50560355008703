import Typewriter from "typewriter-effect";
import Nav from "./Nav";
import Btn from "./Button";

export default function Header()
{
    return (
        <header>
            <Nav />
            <div className="hero">
                <div>
                    <h1>Welcome to Klemweb  <br />
                        <p>
                            <Typewriter options={{ delay: 5 }} onInit={(typewriter) =>
                            {
                                typewriter.typeString("Join us for our exciting online Class and unlock the doors to endless possibilities in the tech universe. Don't miss out, start your journey today!  Online Class").start()
                            }} />
                        </p>
                    </h1>
                    <Btn text={'Register'} rounded={'rounded'} />
                </div>
                <img src="/images/hero_1.png" alt="" />
            </div>
        </header>
    )
}