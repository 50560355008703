import { Link } from "react-router-dom";

export default function Nav()
{
    const handleNavToggle = () =>
    {
        document.querySelector('#links').classList.toggle('show')
        // document.querySelector('body').scrollTo('#courseName')
    }
    return (
        <nav>
            <Link className="nav-logo" to={'/'}>
                <img src="/images/Institute new.png" alt="" />
            </Link>
            <ul id="links">
                <button type="button" className="nav-toggler" onClick={handleNavToggle}>
                    close
                </button>
                <li><Link to={'/software'} onClick={handleNavToggle}>Software Engineering</Link></li>
                <li><Link to={'/productdesign'} onClick={handleNavToggle}>Product Design</Link></li>
                <li><Link to={'/hacking'} onClick={handleNavToggle}> Cyber Security</Link></li>
                <li><Link to={'/datascience'} onClick={handleNavToggle}>Data Science</Link></li>
                <li ><Link to={'/register'} onClick={handleNavToggle}>Register</Link></li>
            </ul>
            <button type="button" className="nav-toggler" onClick={handleNavToggle}>
                menu
            </button>
        </nav>
    )
}