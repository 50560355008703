import Card from "../components/Card";

export default function Cards()
{
    return (
        <>
            <h2>
                Online Courses
            </h2>
            <section id="cards">
                <Card text={'Software Engineering'} image={'/images/Software-Engineering.jpg'} to={'/software'} />
                <Card text={'Cyber Security'} image={'/images/cyber security.jpg'} to={'/hacking'} />
                <Card text={'Product Design'} image={'/images/Product Design.jpg'} to={'/productdesign'} />
                <Card text={'Data Science'} image={'/images/data-analysis.jpg'} to={'/datascience'} />
            </section>
        </>
    )
}