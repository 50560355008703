import Btn from "../components/Button";
import Req from "../components/Requirement";

export default function CyberSecurity()
{
    return (
        <>
            <h2 id="courseName">Cyber Security</h2>
            <div className="course-details">
                <img src="/images/cs.png" alt="" className="details-image" />
                <p className="text">
                    Cyber Security: Defending the Digital World
                    <br />
                    Cyber Security is the practice of legally breaking into systems to find security weaknesses. These experts help organizations protect against cyber threats, ensuring the safety of digital spaces. In a world filled with cyber risks, ethical hackers play a vital role in safeguarding our online world. Join the fight to defend the digital realm!
                    <br />
                    <Btn text={'Register'} rounded={'rounded'} />
                </p>
            </div>
            <Req courseName={'Cyber Security'} image={'/images/cs2.png'} sysReq={'16gb RAM, 512GB Disk Space'} time={'2 hours'} price={'450,000'} dollarPrice={450000 / 1200} />

        </>
    )
}