import { Link } from "react-router-dom";
import Step1 from "./FormSteps/Step1";
import Alert from "./minis/Alert";
import { useEffect, useState } from "react";
import Step2 from "./FormSteps/Step2";
import axios from "axios";
import Step3 from "./FormSteps/Step3";
import { useNavigate } from "react-router-dom"

export default function Register()
{
    const [res, setRes] = useState()
    const [resMessage, setResMessage] = useState()
    const [step2Display, setstep2Display] = useState('none')
    const [step3Display, setstep3Display] = useState('none')
    const [step1Display, setstep1Display] = useState('block')
    const navigate = useNavigate()


    const showRes = (status, message) =>
    {
        document.querySelector(".alert").style.display = 'block'
        setRes(status)
        setResMessage(message)
    }


    const handleStep2Click = (e) =>
    {
        const firstname = document.querySelector('#firstname').value
        const lastname = document.querySelector('#lastname').value
        const email = document.querySelector('#email').value
        let inputs = [firstname, lastname, email];
        let empty = inputs.filter(function (el) { return el === "" })
        if (empty.length > 0)
        {
            showRes('error', 'Input Fields cant be empty')
        }
        else
        {
            axios.get(`https://api.klemweb.com/get.php?checkEmail=${email}`, { withCredentials: true,  headers: {
    'Origin': 'https://e-class.klemweb.com/',
  }, }).then(function (res)
            {
                if (res.data === true)
                {
                    setstep2Display('block')
                    setstep1Display('none')
                } else
                {
                    showRes('error', 'Email Already Exists')
                }
            })
        }
        setTimeout(() =>
        {
            document.querySelector(".alert").style.display = 'none'
        }, 1500);
    }



    const handleStep3Click = (e) =>
    {
        const country = document.querySelector('#country').value
        const phone = document.querySelector('#phone').value
        const state = document.querySelector('#state').value
        const address = document.querySelector('#address').value
        let inputs = [country, phone, state, address];
        let empty = inputs.filter(function (el) { return el === "" })
        if (empty.length > 0)
        {
            showRes('error', 'Input Fields cant be empty')
        } else if (phone.length <= 7)
        {
            showRes('error', 'Invalid Phone Number')
        } else
        {
            setstep2Display('none')
            setstep3Display('block')
        }
        setTimeout(() =>
        {
            document.querySelector(".alert").style.display = 'none'
        }, 1500);
    }



    const handleStep1Click = (e) =>
    {
        setstep2Display('none')
        setstep1Display('block')
    }
    const handleBackClick = (e) =>
    {
        setstep3Display('none')
        setstep2Display('block')
    }


    const handleRegister = (e) =>
    {
        e.preventDefault();
        let data = new FormData(e.target)
        data.append("register", "user");
        axios.post('https://api.klemweb.com/', data, { withCredentials: true,  headers: {
    'Origin': 'https://e-class.klemweb.com/',
  }, }).then(function (res)
        {
            res = res.data
            showRes(res.status, res.message)
            if (res.status === "success")
            {
            } else
            {
                setTimeout(() =>
                {
                    document.querySelector(".alert").style.display = 'none'
                }, 1500);
            }
        })
    }

    return (
        <>
            <form id="registerForm" onSubmit={handleRegister}>
                <h2>Register For Klemweb Online</h2>
                <Alert display='none' status={res} message={resMessage} />
                <Step1 click={handleStep2Click} setDisplay={step1Display} />
                <Step2 click={handleStep3Click} prevClick={handleStep1Click} setDisplay={step2Display} />
                <Step3 setDisplay={step3Display} prevClick={handleBackClick} />
            </form>
        </>
    )
}