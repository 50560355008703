import Btn from "../components/Button";
import Req from "../components/Requirement";

export default function DataScience()
{
    return (
        <>
            <h2 id="courseName">Data Science</h2>
            <div className="course-details">
                <img src="/images/ds.png" alt="" className="details-image" />
                <p className="text">
                    Data Science: Unveiling Insights
                    <br />
                    Data science is all about uncovering hidden insights in data. It uses math, statistics, and programming to predict trends and make informed decisions. In a data-driven world, data science is key to innovation. Join us in unlocking the power of data!
                    <br />
                    <Btn text={'Register'} rounded={'rounded'} />
                </p>
            </div>
            <Req courseName={'Data Science'} image={'/images/ds2.png'} sysReq={'4gb RAM, 256GB Disk Space'} time={'2 hours'} price={'200,000'} dollarPrice={Math.round(200000 / 1200)} />
        </>
    )
}