import { useEffect, useState } from "react";
import FloatingInput from "../minis/FloatingForm";
import axios from "axios";
export default function Step2({ click, prevClick, setDisplay })
{
    const [countrys, setCountrys] = useState([])
    const [states, setStates] = useState([])
    const [contCode, setContCode] = useState('')
    useEffect(() =>
    {
        axios.get('https://api.klemweb.com/get.php?getCountryCode', { withCredentials: true,  headers: {
    'Origin': 'https://e-class.klemweb.com/',
  }, }).then(function (res)
        {
            let allCountrys = []
            res.data.forEach(function (el, i)
            {
                allCountrys.push(el.name);
            })
            setCountrys(allCountrys)
        })
    }, [])
    function setCode(code)
    {
        setContCode(code)
        document.querySelector('#phone').value = code
    }
    const handleCountryChange = (e) =>
    {

        let val = e.target.value
        axios.get('https://api.klemweb.com/get.php?getCountryCode', { withCredentials: true,  headers: {
    'Origin': 'https://e-class.klemweb.com/',
  }, }).then(function (res)
        {
            let code = [...res.data].filter(function (el)
            {
                return el.name === val
            })
            if (code.length === 1)
            {
                setCode(code[0].dial_code)
            }
        })
        axios.get('https://api.klemweb.com/get.php?getCountryStates', { withCredentials: true,  headers: {
    'Origin': 'https://e-class.klemweb.com/',
  }, }).then(function (res)
        {
            let state = [...res.data].filter(function (el)
            {
                return el.name === val
            })
            if (state.length === 1)
            {
                setStates(state[0].states)
            }
        })
    }
    return (
        <div className="step2" style={{ display: setDisplay }}>
            <div className="form-floating mb-3">
                <input name={'country'} className={`form-control`} id={'country'} placeholder="Your Country" list="countries" onChange={handleCountryChange} />
                <label htmlFor={'country'}>Search Your Country</label>
            </div>
            <datalist id="countries">
                {countrys.map((country, key) =>
                    <option value={country} key={key} />
                )}
            </datalist>
            <div className="form-group mb-3">
                <label htmlFor="state">Select State</label>
                <select className="form-control" style={{ padding: '1rem', border: '1px solid black', borderRadius: '.5rem' }} name="state" id="state">
                    {states.length < 1 && <option value={''}>Select Country First</option>}
                    {states.map((state, key) =>
                        <option value={state.name} key={key}>{state.name}</option>
                    )}
                </select>
            </div>
            <div className="form-floating mb-3">
                <input type="tel" name="phone" className="form-control" id="phone" placeholder="Your name" defaultValue={contCode} />
                <label htmlFor="phone">Phone Number</label>
            </div>
            <FloatingInput label='Address' type='address' name='address' id={'address'} />

            <div className="form-group mb-3" >
                <label htmlFor="course">Select Course</label>
                <select className="form-control" name="course" id="course" style={{ padding: '1rem', border: '1px solid black', borderRadius: '.5rem' }}>
                    <option value={'Software Engineering'}>Software Engineering</option>
                    <option value={'Product Design'}>Product Design</option>
                    <option value={'Cyber Security'}>Cyber Security</option>
                    <option value={'Data Science'}>Data Science</option>
                </select>
            </div>
            <div style={{ display: "flex", gap: "1rem" }}>
                <button type="button" id="step1" className="btn btn-primary" onClick={prevClick}>Previous</button>
                <button type="button" id="step2" className="btn btn-primary" onClick={click}>Next</button>
            </div>
        </div>
    )
}