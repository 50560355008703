import FloatingInput from "../minis/FloatingForm";
export default function Step1({ click, setDisplay })
{
    return (
        <div className="step1" style={{ display: setDisplay }}>
            <FloatingInput label='Firstname' type='text' name='firstname' />
            <FloatingInput label='Lastname' type='text' name='lastname' />
            <FloatingInput label='Email' type='email' name='email' />
            <button type="button" id="step2" className="btn btn-primary" onClick={click}>Next</button>
        </div>
    )
}