import Btn from "../components/Button";
import Req from "../components/Requirement";

export default function SEN()
{
    return (
        <>
            <h2 id="courseName">Software Engineering</h2>
            <div className="course-details">
                <img src="/images/hero_2.png" alt="" className="details-image" />
                <p className="text">
                    Software Engineering: Crafting the Digital Future:
                    <br />
                    Software engineering is the art of crafting the digital world. Using languages like Python, Java, and JavaScript, and frameworks like React and Angular, software engineers solve complex problems, create efficient solutions, and build the technology we rely on daily. Collaboration, adaptability, and a passion for learning drive success in this ever-evolving field. The future of software engineering is full of limitless opportunities, with emerging technologies like AI and IoT. Join us on this journey to shape the digital landscape!
                    <br />
                    <Btn text={'Register'} rounded={'rounded'} />
                </p>
            </div>
            <Req courseName={'Software Engineering'} image={'/images/se.png'} sysReq={'4gb RAM, 256GB Disk Space'} time={'2 hours'} price={'300,000'} dollarPrice={300000 / 1200} />
        </>
    )
}