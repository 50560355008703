export default function FloatingInput({ type, label, name, id = label.toLowerCase(), val })
{
    let rand = crypto.randomUUID()
    const handlePasswordToggle = (e) =>
    {
        let passwordInput = document.querySelector('.pword' + rand)
        if (passwordInput.type === 'password')
        {
            passwordInput.setAttribute('type', 'text')
            passwordInput.nextSibling.nextSibling.innerHTML = `<i class="fas fa-eye-slash"></i>`
        } else
        {
            passwordInput.setAttribute('type', 'password')
            passwordInput.nextSibling.nextSibling.innerHTML = `<i class="fas fa-eye"></i>`
        }
    }
    return (
        <div className="form-floating mb-3">
            <input type={type} name={name} className={`form-control ${type === "password" ? 'pword' + rand : null}`} id={id} placeholder="Your name" defaultValue={val} />
            <label htmlFor={label.toLowerCase()}>{label}</label>
            {type === 'password' && <button type="button" style={{ marginRight: ".5rem", color: "#06357a" }} onClick={handlePasswordToggle}>
                <i className="fas fa-eye"></i>
            </button>}
        </div>
    )
}