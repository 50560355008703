import Btn from "./Button";

export default function Req({ courseName, image, sysReq, time, price, dollarPrice })
{
    return (
        <>
            <h2>What More</h2>
            <div className="reqs">
                <img src={image} alt="" />
                <div className="wrapper">
                    <div>
                        <h3 style={{ fontWeight: "bolder" }}>Requirements:</h3>
                        <p>
                            Laptop with minimum of {sysReq} <br />
                            {time} of time every night
                        </p>
                    </div>
                    <div>
                        <h4 style={{ fontWeight: 'bolder' }}>Price</h4>
                        <p>₦{price} - $ {dollarPrice}</p>
                    </div>
                    <Btn text={'Register'} rounded={'rounded'} />
                </div>
            </div>
        </>
    )
}