export default function Footer()
{
    return (
        <footer>
            <div style={{ display: "grid", placeItems: 'center' }}>
                <span style={{ marginBottom: '.5rem' }}>Follow us on</span>
                <div className="socials">
                    <a href="https://web.facebook.com/klemweb"><img src="/images/facebook.svg" alt="" /></a>
                    <a href="https://www.instagram.com/klemweb/"><img src="/images/instagram.svg" alt="" /></a>
                    <a href="https://twitter.com/klemweb"><img src="/images/twitter.svg" alt="" /></a>
                    <a href="https://www.linkedin.com/company/klemweb-integrated-business-ltd"><img src="/images/linkedin.svg" alt="" /></a>
                </div>
            </div>
            <div className="d-flex justify-content-center align-items-center flex-column">
                <span>Contact Us</span>
                <div className="contact">
                    <a href="https://wa.me/2348136528369">+2348136528369</a>
                    <a href="mailto:support@klemweb.com">support@klemweb.com</a>
                </div>
            </div>
            &copy;copyright 2023 Klemweb&trade;
        </footer>
    )
}